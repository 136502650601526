#hobbies {
    max-width: 100dvw;
    overflow: hidden;
}


.budget-wrapper {
    padding: 1em 15%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    overflow-x: auto;
}
