:root {
    --border-color: #444;
    --bg-hover: white;
    --color-checked: white;
    --color: white
}


.radio-group {
    display: flex;
    gap: 5px;
    position: relative;
    margin: 0 auto;
    margin-bottom: 1em;
}

.radio-item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
    white-space: nowrap;
}

.radio-item input {
    appearance: none;
}

.radio-item div {
    color: var(--css-color, white);
    border: 3px solid var(--css-border-color, #444444);
    background: var(--css-bg-color, rgba(68, 68, 68, 0.2));
    display: flex;
    gap: 5px;
    transition: 0.3s;
    border-radius: 30px;
    width: 100%;
    height: 100%;
    padding: 1rem 1.2rem;
    pointer-events: none;
    text-align: center;
    align-items: center;
}

.radio-item input:checked ~ div {
    background: var(--css-checked-bg-color, #32383e);
    border-color: var(--css-checked-border-color, #32383e);
    color: var(--css-checked-color, #FFFDFD) !important;
    box-shadow: 0 0 2px var(--css-checked-shaddow-color, transparent),
    0 0 20px var(--css-checked-shaddow-color, transparent);
}

.radio-item:hover div {
    background: var(--css-hover-bg-color, white);
    border-color: var(--css-hover-border-color, #32383e);
    color: var(--css-hover-color, #32383e);
    box-shadow: 0 0 2px var(--css-hover-shaddow-color, transparent),
    0 0 20px var(--css-hover-shaddow-color, transparent);
}

.radio-item input ~ div svg {
    display: none;
}

.radio-item input:checked ~ div {
    padding-left: 10px;
}

.radio-item input:checked ~ div svg {
    display: initial;
    font-size: 1.6em;
}
