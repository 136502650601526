body, html {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}

* {
    box-sizing: border-box;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


body {
  background: linear-gradient(-45deg, #f3ac9a, #ff97bd, #23a6d5, #6fffdb, #a2ffe7);
  background-size: 400% 400%;
  animation: gradient 25s ease infinite;
    /*background: linear-gradient(-45deg, #ff97bd, #23a6d5);*/
    /*background: #b5e0ff;*/
    /*background-size: 150% 150%;*/
    /*animation: gradient 25s ease infinite;*/
}

@keyframes gradient {
    0% {
        background-position: 0 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}

:root {
    --bottom-height: 7dvh;
}

.MuiToggleButtonGroup-horizontal {
    justify-content: center;
}
