:root {
    --m-color: #6AE2FE;
    --f-color: #FB79B3;
    --t-color: #E5902D;

    --m-colo-hover: #6AE2FE;
    --f-color-hover: #FB79B3;
    --t-color-hover: #E5902D;

    --border-color: #444;
    --bg-hover: white;

    --color-checked: #333333;
    --color: white;
    --bottom-margin: 1em;
}

@media screen and (orientation: landscape) {
    :root {
        --bottom-margin: 0.5em;
    }
}


.gender-icons {
    font-size: 6em;
    position: relative;
    margin-bottom: var(--bottom-margin);
}

.g-icon {
    position: relative;
    z-index: 1;
}

.g-icon.male {
    color: var(--m-color);
    transform: translateX(60px);
}

.g-icon.female {
    color: var(--f-color);
    transform: translateY(30px) rotate(42deg)
}

.g-icon.trans {
    color: var(--t-color);
    transform: translateX(-50px) translateY(50px)
}

#gender {
    display: flex;
}

.gender-input {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
}

.gender-input input {
    appearance: none;
}

.gender-input div {
    color: var(--color);
    display: flex;
    gap: 5px;
    transition: 0.3s;
    border: 3px solid var(--border-color);
    border-radius: 30px;
    width: 100%;
    height: 100%;
    padding: 15px 30px 15px 30px;
    pointer-events: none;
    text-align: center;
    background: rgba(68, 68, 68, 0.2);
    align-items: center;
}

.gender-input:hover div {
    background: var(--bg-hover);
}

.gender-input.male:hover div {
    border-color: var(--m-color);
    color: var(--m-color);
}

.gender-input.female:hover div {
    border-color: var(--f-color);
    color: var(--f-color);
}

.gender-input.trans:hover div {
    border-color: var(--t-color);
    color: var(--t-color);
}

.gender-input input:checked ~ div {
    background: var(--x-color);
    border-color: var(--x-color);
    box-shadow: 0 0 2px var(--x-color), 0 0 5px var(--x-color);
    color: var(--color-checked) !important;
}

.gender-input input ~ div svg {
    display: none;
}
.gender-input input:checked ~ div {
    padding-left: 10px;
}
.gender-input input:checked ~ div svg {
    display: initial;
    font-size: 1.6em;
}
