:root {
    --list-direction: column;
    --list-max-with: 700px;
    --image-width: auto;
    --body-direction: column;
    --image-padding: 5px;
    --content-direction: column;
}

/* Smartphones */
@media only screen and (min-width: 321px) and (max-device-width: 768px) {
    :root {
        /*--body-direction: row;*/
        /*--image-width: 150px;*/
        /*--max-description-width: 250px;*/
        /*--content-direction: row;*/
    }
}

/* iPads and big landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    :root {
        --body-direction: row;
        --image-width: 150px;
        --max-description-width: 250px;
        --content-direction: row;
    }
}

.gifts-wrapper {
    display: flex;
}

.gifts-list {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 10px;
    flex-direction: var(--list-direction);
    max-width: var(--list-max-with);
    margin: 0 auto;
}

.gift-item {
    display: flex;
    background: white;
    width: 100%;
    border-radius: 40px 40px 35px 35px;
    flex-direction: var(--list-direction);
    padding: 20px;
}

.gift-title {
    font-size: 20px;
    font-weight: bold;
    padding: 20px;
    color: #2C3A4D;
}

.gift-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: var(--content-direction);
}

.gift-description {
    color: #2E3B4E;
}

.gift-link {
    /*color: #D8D8D8;*/
    color: #2C3A4D;
    /*background: #2C3A4D;*/
    background: #EDC251;
    padding: 20px;
    text-transform: unset;
    text-decoration: unset;
    margin: 20px auto;
    width: 300px;
    border-radius: 20px;
    border: 3px solid #2C3A4D;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
}

.gift-image {
    width: var(--image-width);
    padding: var(--image-padding);
}

.gift-link svg{
    font-size: 25px;
}

.gift-link:active {
    background: #FFFDFD;
    color: #2C3A4D;
}

.groups {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: max-content;
    max-width: 100dvw;
}
