:root {
    --footer-height: 7dvh;
    --nav-height: 6dvh;
    --page-padding: 1dvh 0;
    --nav-position: absolute;
    --cards-carousel-height: 420px;
    --cards-carousel-max-width: 500px;
    --cards-carousel-padding: 0.5em 4em;
    --slider--active-font-size: 12em;
    --slider-font-size: 10em;
    --hobbies-wrapper-height: 64dvh;
    --hobbies-wrapper-direction: row;
    --var-bar-max-widht: 900px;
    --wave-height: 108dvh;
    --wave-bottom: -8dvh;
    --footer-position: initial;
    --h2-font-size: 30px;
    --footer-position-right: auto;
    --footer-widht: 100%;
    --nav-top: 0.6em;
    --main-ttl-padding: 30px;
    --logo-top: -50px
}

@media screen and (orientation: landscape) {
    :root {
        --h2-font-size: 25px;
        --wave-height: 120dvh;
        --wave-bottom: -20dvh;
        --footer-height: 7dvw;
        --footer-position: absolute;
        --nav-height: 6dvw;
        --page-padding: 3dvh 0;
        --nav-position: absolute;
        --nav-top: 0.4em;
        --cards-carousel-height: 220px;
        --cards-carousel-max-width: 800px;
        --cards-carousel-padding: 0em 12em;
        --slider--active-font-size: 13em;
        --slider-font-size: 11em;
        --hobbies-wrapper-height: 35dvh;
        --var-bar-max-widht: 100%;
        --footer-position-right: 0;
        --footer-widht: max-content;
        --main-ttl-padding: 10px;
        --logo-top: -35px
    }
}

@media (max-width: 992px) {
    h3 {
        font-size: 20px;
    }

}

.App {
    text-align: center;
}

/*h2 {*/
/*    font-size: var(--h2-font-size);*/
/*}*/

.App-logo {
    height: 40vmin;
    pointer-events: none;
    position: relative;
    top: var(--logo-top);
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: jump-shaking 1s infinite;;
    }
}

@keyframes jump-shaking {
    0% {
        transform: translateX(0)
    }
    25% {
        transform: translateY(-9px)
    }
    35% {
        transform: translateY(-9px) rotate(17deg)
    }
    55% {
        transform: translateY(-9px) rotate(-17deg)
    }
    65% {
        transform: translateY(-9px) rotate(17deg)
    }
    75% {
        transform: translateY(-9px) rotate(-17deg)
    }
    100% {
        transform: translateY(0) rotate(0)
    }
}

.App-header {
    /*background-color: #282c34;*/
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

/*@keyframes App-logo-spin {*/
/*  from {*/
/*    transform: rotate(0deg);*/
/*  }*/
/*  to {*/
/*    transform: rotate(160deg);*/
/*  }*/
/*}*/

@keyframes App-logo-spin {
    0% {
        transform: rotate(0deg);
    }
    30% {
        transform: rotate(20deg);
    }
    72% {
        transform: rotate(-20deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

.page {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 2;
    overflow: auto;
}

.white-circle {
    background: white;
    border-radius: 50%;
    width: 50vmin;
    height: 50vmin;
    display: flex;
    align-items: center;
    justify-content: center;
}

.middle {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-width: 100dvw;
}


#age {
    width: 100%;
    overflow: hidden;
}

/*.footer {*/
/*    flex-direction: column;*/
/*    justify-content: center;*/
/*    padding: 0 1.2em;*/
/*    height: var(--footer-height);*/
/*    position: var(--footer-position);*/
/*    bottom: 0;*/
/*    margin: 0 auto;*/
/*    right: var(--footer-position-right);*/
/*    width: var(--footer-widht);*/
/*}*/

input[type=submit] {
    outline: none;
    border: none;
    text-decoration: unset;
    color: #282c34;
    padding: 1em;
    background: #FFFDFD;
    border-radius: 10%;
    box-shadow: 3px 3px 3px #EBEBF8;
    flex-shrink: 0;
    display: flex;
    width: min-content;
}

input[type=submit][disabled] {
    color: #808080;
}

input[type=text] {
    font-size: 16px;
}

.back, .next, .icon-button {
    display: flex;
    background: transparent;
    box-shadow: none;
    /*font-size: 3.4ex;*/
    color: #282c34;
    padding: 0;
    border: none;
}

.icon-button:active,
.back:active,
.next:active,
.next:corner-present {
    color: #13131a;
}

.next[disabled],
.icon-button[disabled] {
    color: #989898;
}


.button-19 {
    background-color: #1899D6;
    border: solid transparent;
    border-radius: 16px;
    border-width: 0 0 4px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: din-round, sans-serif;
    font-size: 1.2em;
    font-weight: 700;
    letter-spacing: .8px;
    line-height: 20px;
    margin: 0;
    outline: none;
    overflow: visible;
    padding: 10px 18px;
    text-align: center;
    text-transform: uppercase;
    touch-action: manipulation;
    transform: translateZ(0);
    transition: filter .2s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    text-decoration: unset;
}

.button-19:after {
    background-clip: padding-box;
    background-color: #1CB0F6;
    border: solid transparent;
    border-radius: 16px;
    border-width: 0 0 4px;
    bottom: -4px;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
}

.button-19:focus {
    user-select: auto;
}

.button-19:hover:not(:disabled),
.button-19:active:not(:disabled) {
    filter: brightness(1.1);
    -webkit-filter: brightness(1.1);
}

.button-19:disabled {
    cursor: auto;
}


.swiper-slide {
    justify-content: center;
    align-items: center;
    opacity: 0.5;
    font-size: var(--slider-font-size);
    font-weight: bold;
    display: flex !important;
}

.swiper-slide-visible, .swiper-slide-active {
    opacity: 1;
    color: white;
    font-size: var(--slider--active-font-size);
    font-weight: bold;
    line-height: 1em;
}

.swiper {
    width: 100dvw;
    max-width: var(--cards-carousel-max-width);
    height: var(--cards-carousel-height);
    padding: var(--cards-carousel-padding) !important;
}

@keyframes gradient {
    0% {
        background-position: 0 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}

.swiper-3d .swiper-slide-shadow {
    border-radius: 18px;
}


* {
    margin: 0;
    padding: 0;
}

/*section {*/
/*    position: absolute;*/
/*    width: 100dvw;*/
/*    height: var(--wave-height);*/
/*    background: rgba(53, 134, 255, 0.5);*/
/*    overflow: hidden;*/
/*    bottom: var(--wave-bottom);*/
/*    z-index: 1;*/
/*}*/

/*section .air {*/
/*    position: absolute;*/
/*    bottom: 0;*/
/*    left: 0;*/
/*    width: 100%;*/
/*    height: 100px;*/
/*    background: url(./wave.png);*/
/*    background-size: 1000px 100px*/
/*}*/

/*section .air.air1 {*/
/*    animation: wave 30s linear infinite;*/
/*    z-index: 1000;*/
/*    opacity: 1;*/
/*    animation-delay: 0s;*/
/*    bottom: 0;*/
/*}*/

/*section .air.air2 {*/
/*    animation: wave2 15s linear infinite;*/
/*    z-index: 999;*/
/*    opacity: 0.5;*/
/*    animation-delay: -5s;*/
/*    bottom: 10px;*/
/*}*/

/*section .air.air3 {*/
/*    animation: wave 30s linear infinite;*/
/*    z-index: 998;*/
/*    opacity: 0.2;*/
/*    animation-delay: -2s;*/
/*    bottom: 15px;*/
/*}*/

/*section .air.air4 {*/
/*    animation: wave2 5s linear infinite;*/
/*    z-index: 997;*/
/*    opacity: 0.7;*/
/*    animation-delay: -5s;*/
/*    bottom: 20px;*/
/*}*/

/*@keyframes wave {*/
/*    0% {*/
/*        background-position-x: 0;*/
/*    }*/
/*    100% {*/
/*        background-position-x: 1000px;*/
/*    }*/
/*}*/

/*@keyframes wave2 {*/
/*    0% {*/
/*        background-position-x: 0;*/
/*    }*/
/*    100% {*/
/*        background-position-x: -1000px;*/
/*    }*/
/*}*/

.budgetButton {
    font-size: 0.9em !important;
    padding: 3px 7px !important;
    border-radius: 14px !important;
    color: #272930 !important;
}

.budgetButton[aria-pressed="true"] {
    background: #32383e !important;
    color: #DDE7EE !important;
}

.hobbies-wrapper {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.normal-wrapper {
    align-items: center;
    background: #e8eeff;
    border-radius: 100px;
    color: #1a5cff;
    display: inline-flex;
    flex-direction: row;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    justify-content: center;
    line-height: 17px;
    margin: 0;
    padding: 5px 10px;
}

.normal-wrapper[data-isselected=true] {
    background: #1a5cff;
    color: #fff;
}

.no-scroll-line::-webkit-scrollbar {
    display: none;
}

.input-text {
    padding: 10px;
    border-radius: 15px;
    box-shadow: 0 0 3px #ddd;
    border: 3px solid #4f9fff;
    margin: 20px;
}

.main-ttl {
    padding-top: var(--main-ttl-padding) !important;
}
