:root {
    --m-bottom: 40px;
    --links-padding-top: 20px;
}

@media only screen and (min-width: 321px) and (max-device-width: 768px) {
    :root {
        --m-bottom: 10px;
        --links-padding-top: 10px;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    :root {
        --m-bottom: 15px;
        --links-padding-top: 10px;
    }
}

.welcome-button {
    font-size: 2.2em;
    padding: 20px;
    margin-bottom: var(--m-bottom);
}

.links {
    display: flex;
    align-items: center;
    padding: 10px;
    padding-top: var(--links-padding-top);
    gap: 20px;
    font-size: 12px;
}
