.terms {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    padding: 40px 10px;
    margin: 0 auto;
}

.terms h1, .terms h2 {
    color: #333;
}

.terms li {
    list-style: none;
}
