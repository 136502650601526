/* desktop and defaults*/
:root {
    --flex-direction: row;
    --background: red;
    --nav-flex: 1;
    --page-flex: 3;
    --nav-aling-items: center;
    --nav-justify-content: center;
    --nav-max-width: 500px;
    --nav-font-size: 2.6em;
    --nav-align-items: center;
    --nav-position: relative;
    --page-title-font-size: 30px;
    --page-title-padding: 8px 35px;
    --main-sub-ttl-font-size: 20px;
}


/* Smartphones */
@media only screen and (min-width: 321px) and (max-device-width: 768px) {
    :root {
        --background: green;
        --flex-direction: column;
        --nav-font-size: 1.4em;
        --nav-flex: 0;
        --nav-aling-items: start;
        --nav-justify-content: start;
        --nav-position: absolute;
        --footer-position-px: 15px;
        --nav-position-px: 10px;
        --main-sub-ttl-font-size: 17px;
    }
}

/* iPads and big landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    :root {
        --background: purple;
        --flex-direction: row;
        --nav-max-width: 70px;
        --nav-font-size: 1.6em;
        --main-sub-ttl-font-size: 15px;
        --page-title-padding: 5px 25px 0 25px;
    }
}

.layout {
    /*background: var(--background);*/
    padding: 0;
    margin: 0 auto;
    width: 100dvw;
    height: 100dvh;
    display: flex;
    flex-direction: var(--flex-direction);
    position: relative;
}


.footer, .nav {
    display: flex;
    z-index: 3;
    flex: var(--nav-flex);
    align-items: var(--nav-align-items);
    justify-content: var(--nav-justify-content);
    max-width: var(--nav-max-width);
    position: var(--nav-position);
}

.footer {
    bottom: var(--footer-position-px, initial);
    right: var(--footer-position-px, initial)
}

.nav {
    top: var(--nav-position-px, initial);
    left: var(--nav-position-px, initial)
}

.footer .next, .nav .back {
    height: 100%;
    width: 100%;
    display: flex;
    flex: 1;
    text-align: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    /*background: rgba(44, 58, 77, 0.1);*/
}

.footer *, .nav * {
    font-size: var(--nav-font-size);
}

.footer:hover, .nav:hover {
    background: rgba(44, 58, 77, 0.1);
}

.page {
    flex: var(--page-flex);
}

.page-title {
    font-size: var(--page-title-font-size);
    color: #2C3A4D;
    padding: var(--page-title-padding, 10px);
    font-weight: 500;
}

.sub-title {
    color: #2C3A4D;
    font-size: 24px;
    position: sticky;
    top: 0;
}

.main-sub-ttl {
    font-size: var(--main-sub-ttl-font-size);
    color: #2C3A4D;
}
