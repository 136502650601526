.gift-group {
    display: flex;
    gap: 10px;
    text-align: center;
    flex-direction: column;
}

.group-title {
    font-size: 1.3em;
    padding: 5px 5px 10px 5px;
}

.group-title a {
    color: #2C3A4D;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.gift-items {
    display: flex;
    gap: 10px;
    flex-direction: row;
    overflow-y: auto;
    padding: 0 15px;
}

.gift {
    position: relative;
    background: white;
    border-radius: 10px;
    padding: 10px;
    min-height: 400px;
    min-width: 340px;
}

.gift-image {
    max-width: 320px;
    max-height: 380px;
}

.price {
    position: absolute;
    top: 5px;
    background: rgba(0, 0, 0, .7);
    border-radius: 5px;
    color: whitesmoke;
    right: 5px;
    padding: 2px 10px;
}

.oldPriceText {
    text-decoration: line-through;
    font-size: 13px;
    color: lightgray;
    padding: 0 5px;
}

.gift-link2 {
    color: #2C3A4D;
    background: #EDC251;
    padding: 5px;
    text-transform: unset;
    text-decoration: unset;
    margin: 5px auto;
    border-radius: 5px;
    border: 2px solid #2C3A4D;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    position: absolute;
    z-index: 2;
    bottom: 10px;
    right: 10px;
    left: 10px;
}

.empty-card-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.gift-link3 {
    color: #2C3A4D;
    font-size: 1.4em;
    padding: 5px;
    text-transform: unset;
    text-decoration: unset;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    align-self: center;
}

.gift-description {
    position: absolute;
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.7);
    color: whitesmoke;
    padding: 10px 10px 45px 10px;
    z-index: 1;
    bottom: 10px;
    left: 5px;
    right: 5px;
    text-shadow: 1px 1px 1px black;
    word-break: break-word;
}

.trimmed-vertical {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--clam-count, 4);
    overflow: hidden;
}
