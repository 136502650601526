:root {
    --light-bg: white;
    --light-color: #999;
    --light-border: #4f9fff;

    --light-bg-hover: white;
    --light-color-hover: #4f9fff;
    --light-border-hover: #3990f8;

    --light-bg-selected: #007BFF;
    --light-color-selected: white;
    --light-border-selected: #3990f8;
}

.list-item {
    position: relative;
    border-radius: 30px;
    box-sizing: border-box;
    transition: 0.3s;
    padding: 14px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.list-item svg {
    position: absolute;
    right: 5px;
}

.list-item.light {
    color: #999;
    background: var(--light-bg);
    border: 3px solid var(--light-border);
}

.list-item.selected {
    background: var(--light-bg-selected);
    border: 3px solid var(--light-border-selected);;
    box-shadow: 0 0 2px var(--light-border-selected);;
    color: var(--light-color-selected);
}

.list-item.light:not(.selected):hover {
    border: 3px solid var(--light-border-hover);
    color: var(--light-color-hover);
}




