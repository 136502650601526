.age-picker {
    display: flex;
    gap: 10vw;
    overflow: auto;
    /*-ms-overflow-style: none;*/
    /*scrollbar-width: none;*/
    scroll-snap-type: x mandatory;
    cursor: ew-resize;
    padding: 0 30dvw;
    height: 80dvh;
    align-items: center;
    user-select: none;
}

.age-picker * {
    user-select: none;
}


.age-picker:active {
    cursor: grabbing;
}

.age-picker div {
    min-width: 220px;
    max-width: 220px;
    text-align: center;
    scroll-snap-align: center;
    font-weight: bold;
    font-size: 10em;
    color: rgba(51, 51, 51, 0.6);
}

.age-picker div.highlight {
    color: white;
}

.middleX {
    position: absolute;
    width: 220px;
    height: 1px;
    border: 1px solid red;
    font-size: 12em;
    left: calc(50% - 110px);
    visibility: hidden;
}
